import { Flex, Heading, Image, SimpleGrid, Text } from '@chakra-ui/react';
import type { FC } from 'react';

import {
  client1,
  client2,
  client3,
  client4,
  client5,
  client6,
  client7,
  client8,
} from '~/assets/images/clients';
import { LandingCtaButton } from '~/components/buttons/LandingCtaButton';
import { Stars } from '~/components/other/Stars';
import { useTransitionLoading } from '~/hooks/useTransitionLoading';

const data = [
  {
    name: 'Emily Park',
    image: client6,
    date: 'October, 2022',
    message:
      'I gifted a moon artwork to my girlfriend capturing the night we first met, and she was speechless. The artwork is a beautiful representation of that special moment, and it fills our home with warmth and nostalgia every time we glance at it.',
  },
  {
    name: 'Timothy Walker',
    image: client5,
    date: 'June, 2022',
    message:
      'I surprised my dad with a personal moon picture to remind him the first trip into the wild that we had together and it was the best gift I could have given him! The image is absolutely stunning and captures the essence of that special day perfectly. When he received it, he was deeply touched and emotional.',
  },
  {
    name: 'David Nielsen',
    image: client4,
    date: 'August, 2022',
    message:
      "I've commissioned the moon artwork that captures the exact night I've first met my wife, and it's been an incredible addition to our home. The image is absolutely breathtaking and we're both in awe of how perfectly it captures the beauty and magic of that special moment, when we were looking into the moon.",
  },
  {
    name: 'Hanna Rhiel Madsen',
    image: client8,
    date: 'July, 2022',
    message:
      "I've purchased the personal moon picture to celebrate the day I met my boyfriend and I'm so glad I did! The image is absolutely stunning and it was the missing piece in our interior. Every time I look at it, I'm reminded of that special moment and how lucky I am to have met him.",
  },
  {
    name: 'Katherine Martin',
    image: client7,
    date: 'April, 2022',
    message:
      'I received a personalized moon artwork capturing the night of my wedding, and it brought tears to my eyes. The artwork perfectly depicts the magical moment when we said our vows under the starry sky, and every time I look at it, I feel overwhelmed with love and gratitude',
  },
  {
    name: 'Madelyn Philips',
    image: client3,
    date: 'January, 2023',
    message:
      'I ordered a moon artwork for my sister, capturing the night her child was born, and she was over the moon! The artwork perfectly represents the joyous occasion and has become a treasured family heirloom',
  },
  {
    name: 'Abigail Kenter',
    image: client2,
    date: 'January, 2023',
    message:
      "Buying a personal moon picture for my birthday was the best decision! The image is stunning and a great reminder of a special moment. I wish I had bought a larger size to showcase the details, but I'm still grateful for this small piece of art.",
  },
  {
    name: 'Samantha Reynolds',
    image: client1,
    date: 'March, 2022',
    message:
      "I received a personalized moon artwork capturing the night of my proposal, and I couldn't be happier. The artwork perfectly represents that magical moment, and every time I look at it, I'm reminded of the love and excitement I felt when I asked the love of my life to marry me",
  },
];

interface CommunityProps {
  ctaAction?: VoidFunction;
  ctaLabel?: string;
}

export const Community: FC<CommunityProps> = ({ ctaAction, ctaLabel }) => {
  const isLoading = useTransitionLoading();

  return (
    <Flex backgroundColor="secondaryBg">
      <Flex
        maxW="1280"
        mx="auto"
        align="center"
        flexDir="column"
        pt="60px"
        pb="64px"
        paddingInline={{ base: '16px', md: '32px' }}
      >
        <Heading
          fontFamily="Libre Baskerville"
          fontSize={{ base: '3xl', md: '5xl' }}
          mb="24px"
          textAlign="center"
        >
          Thousands of moon lovers worldwide
        </Heading>
        <Flex flexDir="column" width="100%" align="center" mb="40px">
          <Flex align="center" mb="8px">
            <Text
              textAlign="center"
              fontSize={{ base: 'lg', md: 'xl' }}
              mr="12px"
              fontWeight="600"
            >
              Excellent
            </Text>
            <Stars />
          </Flex>
          <Text color="blackAlpha.600" fontSize={{ base: 'sm', md: 'md' }}>
            17k moments captured
          </Text>
        </Flex>
        <SimpleGrid columns={{ base: 2, md: 4 }} gap="8px">
          {data.map((testimonial, index) => (
            <Flex
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              direction="column"
              align="left"
              mb="60px"
              borderWidth="1px"
              borderColor="blackAlpha.50"
              backgroundColor="white"
              borderRadius="8px"
            >
              <Flex flexDir="column">
                <Image
                  loading="lazy"
                  src={testimonial.image}
                  width="auto"
                  height="auto"
                  borderRadius="7px 7px 0 0"
                />
                <Flex flexDir="column" padding="8px">
                  <Text fontWeight="600" fontSize={{ base: 'sm', md: 'md' }}>
                    {testimonial.name}
                  </Text>
                  <Text
                    color="blackAlpha.600"
                    fontSize={{ base: 'sm', md: 'md' }}
                    mb="8px"
                  >
                    {testimonial.date}
                  </Text>
                  <Stars size={20} />
                  <Text
                    mt="16px"
                    color="blackAlpha.800"
                    fontSize={{ base: 'sm', md: 'md' }}
                  >
                    {testimonial.message}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          ))}
        </SimpleGrid>
        {ctaLabel && ctaAction && (
          <LandingCtaButton
            isLoading={isLoading}
            onClick={ctaAction}
            label={ctaLabel}
          />
        )}
      </Flex>
    </Flex>
  );
};
