import { Flex, Heading, Image, SimpleGrid, Text } from '@chakra-ui/react';
import type { FC } from 'react';

import { animatedPrint } from '~/assets/images';
import { moment, timeAndPlace } from '~/assets/images/process';
import { LandingCtaButton } from '~/components/buttons/LandingCtaButton';
import { useTransitionLoading } from '~/hooks/useTransitionLoading';

const steps = [
  {
    key: 'one',
    title: 'Your Time & Place',
    subtitle:
      'Select a date and location of your special moment. We then calculate the position of the moon at that exact moment and place.',
    image: timeAndPlace,
  },
  {
    key: 'two',
    title: 'Your Moment',
    subtitle:
      'You can add a personal note, an inside joke or a quote, that will be the name of the moment.',
    image: moment,
  },
  {
    key: 'three',
    title: 'Your Design',
    subtitle:
      'Choose if you want it framed, and what kind of frame you prefer.',
    image: animatedPrint,
  },
];

interface HowItWorksProps {
  ctaAction: VoidFunction;
  ctaLabel: string;
}

export const HowItWorks: FC<HowItWorksProps> = ({ ctaAction, ctaLabel }) => {
  const isLoading = useTransitionLoading();

  return (
    <Flex backgroundColor="secondaryBg">
      <Flex
        align="center"
        maxW="1280"
        mx="auto"
        direction="column"
        paddingBlock={{ base: '40px', lg: '96px' }}
        paddingInline={{ base: '16px', md: '32px' }}
      >
        <Heading
          fontFamily="Libre Baskerville"
          fontSize={{ base: '3xl', md: '5xl' }}
          mb="40px"
          textAlign="center"
        >
          How it works?
        </Heading>
        <SimpleGrid
          columns={{ base: 1, md: 3 }}
          flexDir="row"
          gap="40px"
          mb="40px"
        >
          {steps.map((step, index) => (
            <Flex flexDir="column" key={step.key}>
              <Image
                loading="lazy"
                objectFit="cover"
                objectPosition="bottom"
                src={step.image}
                height="240px"
                mb="24px"
                width="auto"
              />
              <Heading
                fontFamily="Libre Baskerville"
                fontSize="2xl"
                mb="16px"
                textAlign="center"
              >
                {`${index + 1}. ${step.title}`}
              </Heading>
              <Text
                textAlign="center"
                fontSize={{ base: 'md', md: 'sm' }}
                mb="40px"
              >
                {step.subtitle}
              </Text>
            </Flex>
          ))}
        </SimpleGrid>
        <LandingCtaButton
          isLoading={isLoading}
          onClick={ctaAction}
          label={ctaLabel}
        />
      </Flex>
    </Flex>
  );
};
