import { Flex, Heading, Text } from '@chakra-ui/react';
import { Suspense, type FC } from 'react';

import { instagramCarousel } from '~/assets/images/carousel';
import { LandingCtaButton } from '~/components/buttons/LandingCtaButton';

import { Carousel } from './components/Carousel';
import { useTransitionLoading } from '~/hooks/useTransitionLoading';

interface UniverseOfMemoriesProps {
  ctaAction: VoidFunction;
  ctaLabel: string;
}

export const UniverseOfMemories: FC<UniverseOfMemoriesProps> = ({
  ctaAction,
  ctaLabel,
}) => {
  const isLoading = useTransitionLoading();

  return (
    <Flex
      backgroundColor="white"
      flexDir="column"
      paddingBlock={{ base: '40px', md: '80px' }}
    >
      <Flex maxW="1280" mx="auto">
        <Flex
          paddingInline={{ base: '16px', md: '32px' }}
          align="center"
          textAlign={{ base: 'center', md: 'start' }}
          flexDir="column"
          justify="center"
        >
          <Heading
            fontFamily="Libre Baskerville"
            fontSize={{ base: '3xl', md: '5xl' }}
            textAlign="center"
            mb="24px"
          >
            A Universe of Memories: Artworks of Our Customers
          </Heading>
          <Text
            textAlign="center"
            fontSize={{ base: 'md', md: 'lg' }}
            mb="40px"
          >
            Our personalized moon artwork has captured the hearts of countless
            customers who have gifted it to their beloved or received it
            themselves. Share your moon on social media using #getlunar to be
            featured.
          </Text>
        </Flex>
      </Flex>
      <Suspense>
        <Carousel sliderWidthM="3571.7647058824px" src={instagramCarousel} />
      </Suspense>
      <Flex maxW="1280" mx="auto" mt="40px">
        <LandingCtaButton
          isLoading={isLoading}
          onClick={ctaAction}
          label={ctaLabel}
        />
      </Flex>
    </Flex>
  );
};
