import { keyframes } from '@emotion/react';
import { Box } from '@chakra-ui/react';

interface Props {
  sliderWidthM: string;
  src: string;
}

export const Carousel = ({ src, sliderWidthM }: Props) => {
  const slideMobile = keyframes({
    from: { transform: 'translateX(0px)' },
    to: { transform: `translateX(calc(${sliderWidthM} * -1))` },
  });

  return (
    <Box overflow="hidden" width="100vw" height="240px">
      <Box
        sx={{
          height: '100%',
          width: `calc(${sliderWidthM} * 2)`,
          background: `url(${src}) repeat-x`,
          backgroundSize: 'contain',
          animation: `${slideMobile} 40s linear infinite`,
        }}
      />
    </Box>
  );
};
