import { Flex, Heading, Image, SimpleGrid, Text } from '@chakra-ui/react';
import type { FC } from 'react';

import { frame, packaging, paper } from '~/assets/images/features';
import { LandingCtaButton } from '~/components/buttons/LandingCtaButton';
import { Column } from '~/components/layout';
import { useTransitionLoading } from '~/hooks/useTransitionLoading';

const data = [
  {
    key: 'prints',
    image: paper,
    title: 'Premium Prints',
    subtitle: 'Printed on museum grade art matte paper using archival inks.',
  },
  {
    key: 'frames',
    image: frame,
    title: 'Fine frames',
    subtitle:
      'Created from high-quality wood, milled with simple clean lines and a satin finish.',
  },
  {
    key: 'canvas',
    image: packaging,
    title: 'Protected Delivery',
    subtitle:
      'Carefully packaged and wrapped to ensure your order arrives in pristine condition, ready to be displayed and enjoyed',
  },
];

interface MadeToLastProps {
  ctaAction: VoidFunction;
  ctaLabel: string;
}

export const MadeToLast: FC<MadeToLastProps> = ({ ctaAction, ctaLabel }) => {
  const isLoading = useTransitionLoading();

  return (
    <Flex backgroundColor="white">
      <Flex
        align="center"
        maxW="1280"
        mx="auto"
        direction="column"
        paddingBlock={{ base: '40px', lg: '96px' }}
        paddingInline={{ base: '16px', md: '32px' }}
      >
        <Heading
          fontFamily="Libre Baskerville"
          fontSize={{ base: '3xl', md: '5xl' }}
          mb="16px"
          textAlign="center"
        >
          Made to last for centuries
        </Heading>
        <Text
          fontSize={{ base: 'lg', md: 'xl' }}
          color="blackAlpha.600"
          textAlign="center"
          mb="40px"
        >
          We take pride in using only museum-grade materials to create our
          personalized moon artwork, ensuring that they will last for
          generations to come.
        </Text>
        <SimpleGrid
          columns={{ base: 1, md: 3 }}
          flexDir="row"
          gap="40px"
          mb="40px"
        >
          {data.map((point, index) => (
            <Column key={point.key}>
              <Heading
                fontFamily="Libre Baskerville"
                fontSize="xl"
                mb="16px"
                textAlign="center"
              >
                {point.title}
              </Heading>
              <Image
                loading="lazy"
                src={point.image}
                height="240px"
                width="auto"
                objectFit="cover"
                mb="16px"
              />
              <Text textAlign="center">{point.subtitle}</Text>
            </Column>
          ))}
        </SimpleGrid>
        <LandingCtaButton
          isLoading={isLoading}
          onClick={ctaAction}
          label={ctaLabel}
        />
      </Flex>
    </Flex>
  );
};
