import { Flex, Heading, Image, SimpleGrid, Text } from '@chakra-ui/react';
import type { FC } from 'react';

import { anniversary, baby, date, wedding } from '~/assets/images/occasions';
import { LandingCtaButton } from '~/components/buttons/LandingCtaButton';
import { Column } from '~/components/layout';
import { useTransitionLoading } from '~/hooks/useTransitionLoading';

const data = [
  {
    key: 'baby',
    image: baby,
    title: 'Child Birth',
    subtitle:
      '"I bought this as a Christmas present for my husband to mark the birth of our first child. He unwrapped it in front of the whole family and there was not a dry eye in the room."',
  },
  {
    key: 'marriage',
    image: wedding,
    title: 'Marriage',
    subtitle:
      '"Excellent wedding present, so unique and personal. We ordered it large so wedding guests could sign in the margins around the sky and it came out gorgeous!"',
  },
  {
    key: 'anniversary',
    image: anniversary,
    title: 'Anniversary',
    subtitle:
      '"I bought this for my husband for our 51st wedding anniversary. More than anything we own, it reminds us of that day so long ago. It was beautifully executed and we love it. Thank you!"',
  },
  {
    key: 'date',
    image: date,
    title: 'First Date',
    subtitle: `"As a memento of our first date, I've gifted my boyfriend this artwork... His reaction alone is one of my most treasured memories"`,
  },
];

interface OccasionsProps {
  ctaAction: VoidFunction;
  ctaLabel: string;
}

export const Occasions: FC<OccasionsProps> = ({ ctaAction, ctaLabel }) => {
  const isLoading = useTransitionLoading();

  return (
    <Flex backgroundColor="white">
      <Flex
        align="center"
        maxW="1280"
        mx="auto"
        direction="column"
        paddingBlock={{ base: '40px', lg: '96px' }}
        paddingInline={{ base: '16px', md: '32px' }}
      >
        <Heading
          fontFamily="Libre Baskerville"
          fontSize={{ base: '3xl', md: '5xl' }}
          mb="16px"
          textAlign="center"
        >
          A Gift That Shines on Every Occasion
        </Heading>
        <Text
          fontSize={{ base: 'lg', md: 'xl' }}
          color="blackAlpha.600"
          textAlign="center"
          mb="40px"
        >
          {`Imprint a memory of a child's birth, a marriage proposal, a first date
          or any other special moment in a beautiful and timeless way.`}
        </Text>
        <SimpleGrid
          columns={{ base: 1, md: 4 }}
          flexDir="row"
          gap="40px"
          mb="40px"
        >
          {data.map(point => (
            <Column key={point.key}>
              <Heading
                fontFamily="Libre Baskerville"
                fontSize="xl"
                mb="16px"
                textAlign="center"
              >
                {point.title}
              </Heading>
              <Image
                loading="lazy"
                objectFit="cover"
                src={point.image}
                maxWidth="100%"
                height="auto"
                mb="16px"
              />
              <Text textAlign="center">{point.subtitle}</Text>
            </Column>
          ))}
        </SimpleGrid>
        <LandingCtaButton
          isLoading={isLoading}
          onClick={ctaAction}
          label={ctaLabel}
        />
      </Flex>
    </Flex>
  );
};
